import React from "react";
import { Button } from "@anchor/react-components/dist/lib/components";
import { useNavigate } from  "react-router-dom"
import vessel from "../../Assets/Images/vessel-dark.png";
import "./AccessDenied.scss";
const AccessDenied = () => {
    const history = useNavigate();
  return (
    <div className="access-denied">
      <div className="text-section">
        <h1>Access Denied</h1>
        <p>You do not have permission to access this page.</p>
      </div>
      <div className="Image-section">
        <div className="loader-width">
          <img src={vessel} className="shipLoader" alt="Access Denied" />
        </div>
      </div>
      <Button
        label="Back to Main page"
        fit="small"
        className="btn"
        data-testid="return-to-main-page"
        onClick={() => {
            history('/RequestPage')
        }}/>
    </div>
  );
};
export default AccessDenied;
