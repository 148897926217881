// React
import { useState } from "react";

// Anchor Component
import TableV2 from "@anchor/react-components/dist/lib/components/TableV2";
import { Notification } from "@anchor/react-components/dist/lib/components";

// Model
import { ImpactResult, ProposalData } from "../ImpactProposalModel";
import { DtcoModelData } from "../../DtcoModel";

// Hooks
import useProcessedData from "../../../../utils/hooks/useProcessedData";

// Styles
import "./ImpactProposalTable.scss";

interface ImpactProposalTableProps {
  tableData: DtcoModelData[];
  onSelect: (column: string) => void;
}

type SortingRule = {
  id: string;
  desc: boolean;
};

const ImpactProposalTable = ({
  tableData,
  onSelect,
}: ImpactProposalTableProps) => {
  const { result, proposedImpactData } = useProcessedData(tableData);
  const [selectedOption, setSelectedOption] = useState(result[0]?.option);
  const proposalColumns = getProposalColumns(result, proposedImpactData);

  const handleSort = (event: SortingRule[]) => {
    const columnName = event[0]?.id;
    onSelect(columnName !== undefined ? columnName : selectedOption);
    if (!columnName) return;
    setSelectedOption(columnName);
  };

  const getCellStyle = (row: any, column: any) => {
    if (column.id === selectedOption) {
      return { backgroundColor: "#E9F6FC" };
    }
  };

  return (
    <>
      <h6 className="mt-2">Proposal to FBP and Deployment Head</h6>
      <Notification
        body={`Please choose the proposal option by clicking on the option name header. "${selectedOption}" is currently selected for the proposal.`}
        variant="info"
        fit="small"
      />
      <div className="pt-2 impact-proposal-details">
        <TableV2
          columnData={proposalColumns}
          defaultData={proposedImpactData}
          onSorting={handleSort}
          getCellStyle={getCellStyle}
          manualSorting={true}
          stickyHeader={true}
          stickyFooter={true}
          maxHeight={205}
        />
      </div>
    </>
  );
};

const getProposalColumns = (
  result: ImpactResult[],
  proposedImpactData: ProposalData[]
) => {
  const proposalColumns = [
    {
      accessorKey: "service",
      footer: "Impact",
      enableSorting: false,
      header: "Service",
      id: "service",
      meta: {
        type: "custom",
      },
    },
    // loop through result to generate columns dynamically for options
    ...result.map((item: ImpactResult, index: number) => ({
      accessorKey: item.option.replace(/\s/g, "").toLowerCase(),
      footer: () => (
        <span>
          {Math.round(
            proposedImpactData.reduce(
              (acc: any, row) =>
                acc + row[item.option.replace(/\s/g, "").toLowerCase()],
              0
            ) * 100
          ) / 100}
        </span>
      ),
      sortable: false,
      header: () => (
        <span title={item.option} className="proposal-header">
          {item.option}
        </span>
      ),
      id: item.option,
      meta: {
        type: "custom",
      },
    })),
  ];
  return proposalColumns;
};

export default ImpactProposalTable;
