import { DtcoModelData } from '../pages/DtcoOverviewPage/DtcoModel';

// interface for unique services
interface UniqueService {
  serviceName: string;
  serviceId: number;
}

// interface for result
interface ImpactResult {
  option: string;
  services: {
    serviceId: number;
    serviceName: string;
    vesselName: string;
  }[];
  nopat: number;
  rank: number;
}

type CombinationResponseType = [number, string];

const getUniqueServices = (tableData: DtcoModelData[]): UniqueService[] =>
  Array.from(
    new Set(tableData.map((item: DtcoModelData) => item.serviceId))
  ).map((serviceId) => ({
    serviceName: tableData.find((item) => item.serviceId === serviceId)!
      .serviceName,
    serviceId,
  }));

const getServiceName = (
  serviceId: number,
  uniqueServices: UniqueService[]
): string => {
  const service = uniqueServices.find(
    (item: UniqueService) => item.serviceId === serviceId
  );
  return service ? service.serviceName : '';
};

const getBaselineData = (tableData: DtcoModelData[]) => {
  const baselineData = tableData
    .filter((item) => item.isImpactBaseline)
    .map((item) => [item.serviceId, item.vesselName]);
  return baselineData;
};

const permutations = (tableData: DtcoModelData[]) => {
  const uniqueServices = getUniqueServices(tableData);
  let baselineData = getBaselineData(tableData);
  const services: number[] = uniqueServices.map(
    (item: UniqueService) => item.serviceId
  );

  const serviceVesselMapping = tableData.reduce(
    (acc: { [key: number]: string[] }, row) => {
      if (!acc[row.serviceId]) {
        acc[row.serviceId] = [];
      }
      acc[row.serviceId].push(row.vesselName);
      return acc;
    },
    {} as { [key: number]: string[] }
  );

  const vessels: string[] = tableData
    .filter((item: DtcoModelData) => item.isImpactBaseline)
    .map((item: DtcoModelData) => item.vesselName);

  // assignRank is based on nopat value of result highest is rank 1 lowest rank as per the result array length
  const assignRank = (result: ImpactResult[]): ImpactResult[] => {
    result.sort((a, b) => b.nopat - a.nopat);
    result.forEach((item, index) => {
      item.rank = index + 1;
    });
    return result;
  };

  if (vessels.length === 0) {
    return [];
  }

  const combinations = generateCombinations(services, serviceVesselMapping);
  const result = generateResult(combinations, baselineData, tableData);

  result.forEach((result: ImpactResult) => {
    const serviceIds = result.services.map((service) => service.serviceId);
    const vessels = Array.from(
      new Set(result.services.map((service) => service.vesselName))
    );
    result.nopat = +(
      getNopat(serviceIds, vessels, tableData) / 1000000
    ).toFixed(2);
  });

  return assignRank(result);
};

const getNopat = (
  services: number[],
  vessels: string[],
  tableData: DtcoModelData[]
): number =>
  services.reduce((totalNopat, service, i) => {
    const data = tableData.find(
      (item) => item.serviceId === service && item.vesselName === vessels[i]
    );
    return data?.nopat ? totalNopat + data.nopat : totalNopat;
  }, 0);

function generateCombinations(
  services: number[],
  vessels: { [key: number]: string[] }
) {
  // Generate all possible combinations of vessels for each service
  const allCombinations = services.reduce(
    (combinations: CombinationResponseType[][], service: number) => {
      const newCombinations: CombinationResponseType[][] = [];
      vessels[service].forEach((vessel: string) => {
        combinations.forEach((combination: CombinationResponseType[]) => {
          newCombinations.push([
            ...(combination),
            [service, vessel],
          ]);
        });
      });
      return newCombinations;
    },
    [[]]
  );

  // Filter out the combinations that do not meet the criteria
  const validCombinations = allCombinations.filter(
    (combination: CombinationResponseType[]) => {
      const usedVessels = new Set(
        combination.map(([service, vessel]: CombinationResponseType) => vessel)
      );
      return usedVessels.size === combination.length;
    }
  );
  return validCombinations;
}

// function which takes combinations and returns the result
function generateResult(
  combinations: CombinationResponseType[][],
  baselineData: (number | string)[][],
  tableData: DtcoModelData[]
) {
  combinations.forEach((combination: CombinationResponseType[], i: number) => {
    const secondEntries = combination.map(
      (subArray: CombinationResponseType) => subArray[1]
    );
    const thirdEntries = baselineData.map(
      (subArray: (number | string)[]) => subArray[1]
    );

    const areArraysEqual =
      thirdEntries.length === secondEntries.length &&
      thirdEntries.every((value, index) => value === secondEntries[index])
        ? combination
        : null;

    if (areArraysEqual) {
      const index = combinations.indexOf(combination);
      combinations.splice(index, 1);
      combinations.unshift(areArraysEqual);
    }
  });

  return combinations.map(
    (combination: CombinationResponseType[], i: number) => {
      return {
        option: i == 0 ? 'Baseline' : `Option ${i + 1}`,
        services: combination.map(
          ([service, vessel]: CombinationResponseType) => ({
            serviceId: service,
            serviceName: getServiceName(service, tableData),
            vesselName: vessel,
          })
        ),
        nopat: 0,
        rank: 0,
      };
    }
  );
}

export default permutations;
