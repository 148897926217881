// React
import { useState } from "react";
import { useForm } from "react-hook-form";

// Anchor Components
import {
  Button,
  Modal,
  TextArea,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";

// Services
import { postAPI } from "../../../../Services/APIservices";
import { AppString } from "../../../../utils/AppString";

interface AddCommentsProps {
  showCommentsModal: boolean;
  setShowCommentsModal: (show: boolean) => void;
  selectedServiceRank: number[];
  requestId: string | undefined;
  onCreateNewDTCO: () => void;
}

interface Comment {
  id: number;
  comment: string;
}

const SimulationDataAddFeedbackComponent = ({
  showCommentsModal,
  selectedServiceRank,
  setShowCommentsModal,
  requestId,
  onCreateNewDTCO,
}: AddCommentsProps) => {
  const [showThanksModal, setShowThanksModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    const payload: Comment = {
      id: 0,
      comment: data.comment,
    };
    const url = `/simulation-api/Simulation/SaveRecomendationCommentsAsync/${requestId}`;
    postAPI(url, payload)
      .then((response) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Feedback saved successfully",
          },
          { type: "success" }
        );
        setShowCommentsModal(false);
        setShowThanksModal(true);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while saving feedback.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  return (
    <>
      <Modal
        actions={{
          primaryAction: (
            <Button
              id="save-result-feedback"
              label="Save"
              onClick={handleSubmit(onSubmit)}
            />
          ),
          secondaryAction: (
            <Button
              id="cancel-result-feedback"
              label="Cancel"
              variant="secondary"
              onClick={() => setShowCommentsModal(false)}
            />
          ),
        }}
        heading={AppString.simulationResultFeedback}
        onClose={() => setShowCommentsModal(false)}
        open={showCommentsModal}
        backdropcloseactiondisabled
        height="360"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>
            You choose the result which we have ranked as number{" "}
            {selectedServiceRank[0]}. Before you proceed, please clarify why you
            didn’t choose number 1.
          </p>
          <div>
            <TextArea
              id="add-new-comment"
              {...register("comment", { required: true })}
              maxLength={500}
              placeholder="Maximun 500 characters"
            />
            {errors.comment && (
              <span className="text-danger">{AppString.simulationResultFeedbackRequired}</span>
            )}
          </div>
        </form>
      </Modal>

      <Modal
        actions={{
          primaryAction: (
            <Button
              id="create-proposal-post-feedback"
              loading={loading}
              label="OK, Create New DTCO proposal"
              onClick={() => {
                setLoading(true);
                onCreateNewDTCO();
              }}
            />
          ),
        }}
        heading={AppString.simulationResultFeedbackThankYou}
        showCloseIcon
        onClose={() => setShowThanksModal(false)}
        open={showThanksModal}
        height="200"
        backdropcloseactiondisabled
      >
        <form onSubmit={() => {}}>
          <p>{AppString.simulationResultFeedbackThankYouComment}</p>
          <div></div>
        </form>
      </Modal>
    </>
  );
};

export default SimulationDataAddFeedbackComponent;
