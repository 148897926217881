// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  background: var(--background-color-grey);
}
.no-data .content .center-image {
  border: solid 1px var(--border-color);
  padding: 3px;
  border-radius: 1rem;
  background: var(--color-white);
}
.no-data .content .center-image img {
  height: 12rem;
  border-radius: 1rem;
}
.no-data .content .no-data-text {
  margin-top: 15px;
  text-align: center;
  font-weight: 600;
}
.no-data .content .sub-text {
  text-align: center;
  font-size: var(--font-size-md);
}`, "",{"version":3,"sources":["webpack://./src/Components/NoData/NoData.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,wCAAA;AACJ;AACQ;EACI,qCAAA;EACA,YAAA;EACA,mBAAA;EACA,8BAAA;AACZ;AAAY;EACI,aAAA;EACA,mBAAA;AAEhB;AACQ;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;AACZ;AACQ;EACI,kBAAA;EACA,8BAAA;AACZ","sourcesContent":[".no-data{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 35rem;\n    background: var(--background-color-grey);\n    .content{\n        .center-image{\n            border: solid 1px var(--border-color);\n            padding: 3px;\n            border-radius: 1rem;\n            background: var(--color-white);\n            img{\n                height: 12rem;\n                border-radius: 1rem;\n            }\n        }\n        .no-data-text{\n            margin-top: 15px;\n            text-align: center;\n            font-weight: 600;\n        }\n        .sub-text{\n            text-align: center;\n            font-size: var(--font-size-md);\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
