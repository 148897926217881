// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  align-items: center;
}
.input-container button {
  font-size: 20px;
  padding: 1px 5px;
  margin: 0px;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.input-container button.left-btn {
  border-right: 0px;
}
.input-container button.right-btn {
  border-left: 0px;
}
.input-container input {
  font-size: 14px;
  text-align: center;
  width: 2rem;
  border: 1px solid var(--border-color);
}
.input-container input[type=number]::-webkit-inner-spin-button,
.input-container input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/InputRange/InputRange.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,qCAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AADI;EACE,gBAAA;AAGN;AACE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,qCAAA;AACJ;AACE;;EAIE,gBAAA;EACA,SAAA;AACJ","sourcesContent":[".input-container {\n  display: flex;\n  align-items: center;\n  button {\n    font-size: 20px;\n    padding: 1px 5px;\n    margin: 0px;\n    cursor: pointer;\n    border: 1px solid var(--border-color);\n    &.left-btn {\n      border-right: 0px;\n    }\n    &.right-btn {\n      border-left: 0px;\n    }\n  }\n\n  input {\n    font-size: 14px;\n    text-align: center;\n    width: 2rem;\n    border: 1px solid var(--border-color);\n  }\n  input[type=\"number\"]::-webkit-inner-spin-button,\n  input[type=\"number\"]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
