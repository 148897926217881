// React
import React, { useState } from "react";

// Anchor UI Notification Component
import { Notification } from "@anchor/react-components/dist/lib/components";
import { IconNames } from "@anchor/react-components/dist/lib/components/Icon";

interface NotificationProps {
  message: string;
  title?: string;
  type?:  "info" | "success" | "warning" | "error";
  icon?: IconNames;
  size?: "small" | "medium" | "large";
}

const NotificationComponent: React.FC<NotificationProps> = ({ message, title, type, icon, size }) => {
    const [isNotificationVisible, setNotificationVisible] = useState(true);
    const handleClose = () => {
      setNotificationVisible(false);
    };
    return isNotificationVisible ? (
        <div className="pt-3">
            <Notification
                body={message}
                heading={title}
                onClose={handleClose}
                icon={icon}
                showClose
                variant={type ? type : "info"}
                fit={size}
            />
        </div>
    ) : null;
}

export default NotificationComponent;
