
import { Workbook } from "exceljs";
import * as fs from "file-saver";

export const excelDownload = (name: any, modified_rows: any, header: any, isCharter?:boolean) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet(name);
  //Add Header Row
  let headerRow = worksheet.addRow(header);
  // Cell Style : Fill and Border
  headerRow.eachCell(function (cell: any) {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "42b0d5" },
      bgColor: { argb: "ffffff" },
    };
    cell.font = {
      name: "Maersk Text",
      size: 11,
      bold: true,
      color: { argb: "ffffff" },
    };
    cell.alignment = {
      vertical: "right",
      horizontal: "bottom",
    };
    cell.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  const data = modified_rows?.forEach((d: any) => {
    let row = worksheet.addRow(Object.values(d));
    row.font = {
      name: "Maersk Text",
      size: 10,
    };
    row.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  for (let i = 1; i <= header.length; i++) {
    worksheet.getColumn(i).width = 18;
  }
  worksheet.addRow([data]);
  workbook.xlsx.writeBuffer().then((excelData: any) => {
    const fileName = `${ isCharter ? "TCO_Request_" : "DTCO_Request_"}${new Date().toLocaleString()}`;
    let blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fileName + ".xlsx");
  });
};