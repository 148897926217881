import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";
import RejectProposal from "./AddOnComponent/RejectProposal";
import Table from "./AddOnComponent/Table";
import StepIndicator from "../../Components/StepIndicator/StepIndicator";
import ProposalDeatils from "./AddOnComponent/ProposalDeatils";
import PageLoader from "../../Components/PageLoader/PageLoader";
import { AppString } from "../../utils/AppString";
import { getAPI, postAPI } from "../../Services/APIservices";
import { getUserMailID } from "../../utils/token";
import TradeOwnerListTable from "./AddOnComponent/TradeOwnerListTable";
import { AcceptRejectPayload, DTCORequestProposalResponse } from "./model";
import ConfirmationModalPopUp from "./AddOnComponent/ConfirmationModalPopUp";
import { appRoles } from "../../utils/Constants";
import PermutationTable from "./AddOnComponent/PermutationTable";
import DisplayDeltaProposalTable from "../DtcoOverviewPage/DisplayDelta/DisplayDeltaProposalTable";
import DisplayDeltaTableDetails from "../DtcoOverviewPage/DisplayDelta/DisplayDeltaTableDetails";

const ProposalValidation = () => {
  const history = useNavigate();
  const loggedInUser = sessionStorage.getItem("userRole") ?? "";
  if(loggedInUser === appRoles.DeploymentUser) {
    history("/AccessDenied");
  }
  const [openAcceptPopUp, setOpenAcceptPopUp] = useState(false);
  const [openRejectPopUp, setOpenRejectPopUp] = useState(false);
  const [ownerDetails, setOwnerDetails] =
    useState<DTCORequestProposalResponse>();
  const [isPageLoader, setIsPageLoader] = useState<boolean>(false);
  const params = useParams();

  const [transactionId, setTransactionId] = useState(0);
 // Function to transform all the keys of an object to lowercase since these are stored as raw data
 const transformKeysToLowercase = (data: any[]) => {
  return data?.map((item: any) => {
    return Object.keys(item).reduce((acc: any, key: string) => {
      acc[key.charAt(0).toLowerCase() + key.slice(1)] = item[key];
      return acc;
    }, {});
  });
};
  const getTradeOwnerDeploymentDetails = async () => {
    setIsPageLoader(true);
    const response = await getAPI(
      `dtco-api/dtcosummary/GetDTCORequestProposalResponse/${params.id}`
    );
    const userMailId = await getUserMailID();
    if(response){
      if (loggedInUser === appRoles.DeploymentHead) {
        setTransactionId(response?.deplymentHeadResponse?.reviewerTransactionId);
      } else if (
        loggedInUser === appRoles.FBP &&
        response?.fbpResponse?.reviewerEmail === userMailId
      ) {
        setTransactionId(response?.fbpResponse?.reviewerTransactionId);
      } else if (
        loggedInUser === appRoles.HONP &&
        response?.honpResponse?.reviewerEmail === userMailId
      ) {
        setTransactionId(response?.honpResponse?.reviewerTransactionId);
      }
      const parsedResponse = {
        ...response,
        exceptionalCaseDelta: JSON.parse(response?.exceptionalCaseDelta),
        exceptionalCaseSummary: JSON.parse(response?.exceptionalCaseSummary),
      };
      parsedResponse.exceptionalCaseSummary = transformKeysToLowercase(
        parsedResponse.exceptionalCaseSummary
      );
      parsedResponse.exceptionalCaseDelta = transformKeysToLowercase(
        parsedResponse.exceptionalCaseDelta
      );
      setOwnerDetails(parsedResponse);
    }
    setIsPageLoader(false);
  };
  useEffect(() => {
    getTradeOwnerDeploymentDetails();
  }, []);

  const hanldeAcceptReject = async (
    payload: AcceptRejectPayload,
    toastComment: string
  ) => {
    const response = await postAPI(
      `dtco-api/dtcosummary/SaveDTCORequestProposalResponse/${transactionId}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: toastComment },
        { type: "success" }
      );
      getTradeOwnerDeploymentDetails();
    } else {
      toastEmitter(
        { className: "customClassName", title: AppString.errorToastComment },
        { type: "error" }
      );
    }
  };
  const handleAccept = () => {
    const payload = {
      isAccepted: true,
      rejectionComment: "",
      rejectionTypeId: null,
    };
    hanldeAcceptReject(payload, AppString.acceptToastComment);
  };
  const handleReject = async (payload: AcceptRejectPayload) => {
    hanldeAcceptReject(payload, AppString.rejectedToastComment);
  };
  return (
    <div className="fbpvalidation-page p-3 ">
      <PageLoader isLoader={isPageLoader} />
      <h1 className="header-text mb-3"> New DTCO Proposal</h1>
      <div className="bck-button">
        <Button
          label="DTCO Proposal"
          appearance="alt"
          fit="small"
          icon="arrow-left"
          variant="filled"
          onClick={() => history(`/RequestPage/DtcoOverviewPage/${params.id}`)}
        ></Button>
      </div>
      <StepIndicator
        requestID={params.id}
        triggerPoint={isPageLoader}
      ></StepIndicator>
      <div className="data-section">
        <ProposalDeatils
          reuqestName={ownerDetails?.dtcoRequestData?.requestName}
          createdBy={ownerDetails?.dtcoRequestData?.createdBy}
          driversCategory={ownerDetails?.dtcoRequestData?.dtcoCategoryName}
          dtcoType={ownerDetails?.dtcoRequestData?.dtcoTypeName}
        />
        <div className="tableData-section mb-4">
          <h4 className="sub-header-text mb-3">Proposal from Deployment</h4>
          {ownerDetails?.impactPermutations !== null && (
            <div className="permutation-table mb-3">
              <PermutationTable originalJSON={ownerDetails?.impactPermutations} />
            </div>
          )}
          {ownerDetails?.exceptionalCaseSummary?.length > 0 && (
            <div className="mb-3">
              <DisplayDeltaProposalTable
                displayImpactTableData={ownerDetails?.exceptionalCaseSummary}
                isEbitEdit={false}
              />
            </div>
          )}
 
          {ownerDetails?.exceptionalCaseSummary?.length > 0 && (
            <div className="exceptionCaseDetailTable">
              <DisplayDeltaTableDetails data={ownerDetails?.exceptionalCaseDelta} />
            </div>
          )}
 
          {ownerDetails?.dtcoImpact !== null && (
            <Table originalJSON={ownerDetails?.dtcoImpact} />
          )}
        </div>
        <div className="comment-section mb-4">
          <h4 className="sub-header-text comment-text mb-3">
            Comment from Deployment
          </h4>
          <div className="comment-box comment-div">
            {ownerDetails?.comment}
          </div>
        </div>
        {ownerDetails?.honpResponse !== null && (
          <div className="tableData-section mb-4">
            <TradeOwnerListTable
              tableData={
                ownerDetails?.honpResponse
                  ? [ownerDetails.honpResponse]
                  : []
              }
              sectionRole="HNOP"
              userRole={loggedInUser}
            />
          </div>
        )}
        <div className="tableData-section mb-4">
          <TradeOwnerListTable
            tableData={
              ownerDetails?.fbpResponse
                ? [ownerDetails.fbpResponse]
                : []
            }
            sectionRole="FBP"
            userRole={loggedInUser}
          />
        </div>
        <div className="tableData-section mb-4">
        <TradeOwnerListTable
            tableData={
              ownerDetails?.deplymentHeadResponse
                ? [ownerDetails?.deplymentHeadResponse]
                : []
            }
            sectionRole="deploymentHead"
            userRole={loggedInUser}
          />
        </div>

        {ownerDetails?.showReviewerAction && (
          <div className="button-section">
            <Button
              appearance="default"
              id="primary"
              variant="outlined"
              justifyItems="center"
              label="Reject proposal"
              name="reject"
              fit="small"
              onClick={() => setOpenRejectPopUp(true)}
            />
            <Button
              appearance="default"
              id="primary"
              justifyItems="center"
              label="Accept proposal"
              name="accept"
              fit="small"
              onClick={() => setOpenAcceptPopUp(true)}
            />
          </div>
        )}
      </div>
      <ConfirmationModalPopUp
        isOpen={openAcceptPopUp}
        onClose={() => setOpenAcceptPopUp(false)}
        modalHeader={AppString.acceptModalHeader}
        modalBody={AppString.acceptModalTxt}
        modalButton={{
          primary: "Accept",
          secondary: "Dismiss",
        }}
        submitFun={handleAccept}
      />
      <RejectProposal
        isOpen={openRejectPopUp}
        onClose={() => setOpenRejectPopUp(false)}
        submitFun={handleReject}
      />
    </div>
  );
};
export default ProposalValidation;
