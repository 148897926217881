// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Anchor Components
import {
  Button,
  Modal,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";

//Styles
import "./DisplayDelta.scss";

// Services
import { postAPI } from "../../../Services/APIservices";

// Components
import DisplayDeltaTableDetails from "./DisplayDeltaTableDetails";
import DisplayDeltaProposalTable from "./DisplayDeltaProposalTable";

// Models
import {
  DtcoModelDataDisplay,
  VesselPerformanceComparisonModel,
} from "./DisplayDeltaModel";
import { DtcoModelData } from "../DtcoModel";

interface ImpactProposalModalProps {
  show: boolean;
  tableData: DtcoModelData[];
  onHide: () => void;
  requestId: string | undefined;
  deploymentUse?: boolean;
}

const DisplayDeltaModal: React.FC<ImpactProposalModalProps> = ({
  tableData,
  requestId,
  show,
  onHide,
  deploymentUse,
}) => {
  const navigate = useNavigate();
  const [displayTableData, setDisplayTableData] = useState<
    DtcoModelDataDisplay[]
  >([]);
  const filterDataForSelectedVessel = tableData?.filter(
    (item) => item?.isImpactBaseline === true || item?.isImpactOption === true
  );

  // Use a Map to track unique names
  const vesselNameMap = new Map();

  // Iterate over the data and populate the Map
  filterDataForSelectedVessel?.forEach((item) => {
    if (!vesselNameMap.has(item?.vesselName)) {
      vesselNameMap.set(item?.vesselName, {
        vesselName: item?.vesselName,
        baseline: item?.isImpactBaseline ? item?.nopat : 0,
        proposal: item?.isImpactOption ? item?.nopat : 0,
      });
    } else {
      // If you want to update the existing entry,we have the logic here
      // For example, you could update the baseline or proposal if needed
      const existingItem = vesselNameMap?.get(item?.vesselName);
      if (item?.isImpactBaseline) {
        existingItem.baseline = item?.nopat;
      }
      if (item.isImpactOption) {
        existingItem.proposal = item?.nopat;
      }
      vesselNameMap.set(item?.vesselName, existingItem); // Update the map with the modified object
    }
  });

  // Convert the Map values to an array VesselPerformanceComparisons
  const vesselPerformanceComparisonsList: VesselPerformanceComparisonModel[] =
    Array.from(vesselNameMap.values());

  useEffect(() => {
    //setting the list to get the required data
    const getRequiredTableData: DtcoModelDataDisplay[] =
      filterDataForSelectedVessel?.map((item) => ({
        vesselName: item.vesselName,
        serviceCode: item.serviceCode,
        roundTripDays: item.roundTripDays,
        consupmtionInTons: item.consupmtionInTons,
        consumptionInUSD: item.consumptionInUSD,
        portExpense: item.portExpense,
        rtCost: item.roundTripCost,
        yearlyCost: item.yearlyCost,
        isBaseline: item.isImpactBaseline,
        summaryId: item.requestSummaryId,
        totalFFEPerRT: calculateVolume(item),
        averageCyFFE: calculateAvgCYFFE(item),
        rtCy: calculateRTCY(item),
        yearCy: calculateYearlyCY(item),
        fullYearBenefit: item.nopat,
        ebitByRtDays: 1,
        totalAmount: getNopatRT(item.nopat, item.roundTripDays, 1),
        isImpactOption: item.isImpactOption,
      }));
    setDisplayTableData(getRequiredTableData);
  }, [tableData]);

  //volume calculation
  const calculateVolume = (item: DtcoModelData) => {
    const hhDryDemand = item.hhDryDemand ? item.hhDryDemand / 2 : 0;
    const hhReeferDemand = item.hhReeferDemand ? item.hhReeferDemand : 0;
    const bhDryDemand = item.bhDryDemand ? item.bhDryDemand / 2 : 0;
    const bhReeferDemand = item.bhReeferDemand ? item.bhReeferDemand : 0;
    return hhDryDemand + hhReeferDemand + bhDryDemand + bhReeferDemand;
  };

  //AvgCYFFE  calculation
  const calculateAvgCYFFE = (item: DtcoModelData) => {
    const hhDryCY = item.hhDryCY ? item.hhDryCY : 0;
    const hhReeferCY = item.hhReeferCY ? item.hhReeferCY : 0;
    const bhDryCY = item.bhDryCY ? item.bhDryCY : 0;
    const bhReeferCY = item.bhReeferCY ? item.bhReeferCY : 0;
    const hhDryDemand = item.hhDryDemand ? item.hhDryDemand / 2 : 0;
    const hhReeferDemand = item.hhReeferDemand ? item.hhReeferDemand : 0;
    const bhDryDemand = item.bhDryDemand ? item.bhDryDemand / 2 : 0;
    const bhReeferDemand = item.bhReeferDemand ? item.bhReeferDemand : 0;
    const dryHH = hhDryCY * hhDryDemand;
    const reeferHH = hhReeferCY * hhReeferDemand;
    const dryBH = bhDryCY * bhDryDemand;
    const reeferBH = bhReeferCY * bhReeferDemand;
    const totalFFE = dryHH + reeferHH + dryBH + reeferBH;
    const volume = calculateVolume(item);
    if (volume === 0) {
      return 0;
    } else {
      const avgCYFFE = totalFFE / volume;
      return avgCYFFE;
    }
  };

  //RTCY calculation
  const calculateRTCY = (item: DtcoModelData) => {
    const volume = calculateVolume(item);
    const avgCYFFE = calculateAvgCYFFE(item);
    return volume * avgCYFFE;
  };

  //YearlyCY calculation
  const calculateYearlyCY = (item: DtcoModelData) => {
    const rtCY = calculateRTCY(item);
    const roundTripDays = item.roundTripDays ? item.roundTripDays : 0;
    if (roundTripDays === 0) {
      return 0;
    } else {
      const yearCY = rtCY * (365 / roundTripDays);
      return yearCY;
    }
  };

  //NopatRT calculation
  const getNopatRT = (nopat, roundTripDays, nopatRT) => {
    if (nopat === 0) {
      return 0;
    }
    const nopatPerday = nopat / 365;
    const roundTrip = roundTripDays * nopatRT;
    const resultNopat = nopatPerday * roundTrip;
    return resultNopat;
  };

  //Function to save the delta details and navigate to the next page
  const saveDeltaDetails = async () => {
    const dtcoSummaryExceptionCaseMatrics = displayTableData.map((item) => ({
      serviceCode: item.serviceCode,
      vesselName: item.vesselName,
      roundTripDays: item.roundTripDays,
      consupmtionInTons: item.consupmtionInTons,
      consumptionInUSD: item.consumptionInUSD,
      portExpense: item.portExpense,
      summaryId: item.summaryId,
      rtCost: item.rtCost,
      yearlyCost: item.yearlyCost,
      totalFFEPerRT: item.totalFFEPerRT,
      averageCyFFE: item.averageCyFFE,
      rtCy: item.rtCy,
      yearCy: item.yearCy,
      fullYearBenefit: item.fullYearBenefit,
      ebitByRtDays: item.ebitByRtDays,
      totalAmount: item.totalAmount,
      isBaseline: item.isBaseline,
      isImpactOption: item.isImpactOption,
    }));

    const payload = {
      dtcoSummaryExceptionCaseMatrics: dtcoSummaryExceptionCaseMatrics,
      vesselPerformanceComparisons: vesselPerformanceComparisonsList,
      delta: 0,
      summaryIds: [0],
      requestId: requestId,
    };

    const url = `dtco-api/dtcosummary/SaveDTCOExceptionalCaseMetricsAsync`;
    const response = await postAPI(url, payload);
    try {
      if (response) {
        navigate(`/RequestPage/FBPvalidation?requestId=${requestId}`);
      }
    } catch (error: any) {
      toastEmitter(
        {
          className: "customClassName",
          title: "Error occured while saving the data.",
        },
        { type: "error" }
      );
    }
  };

  return (
    <Modal
      actions={{
        primaryAction: !deploymentUse && (
          <Button
            arialabel="handleConfirmtext"
            label="Create Proposal"
            onClick={saveDeltaDetails}
          />
        ),
        secondaryAction: (
          <Button label="Dismiss" onClick={onHide} variant="outlined" />
        ),
      }}
      width="94%"
      backdropcloseactiondisabled
      dimension="large"
      heading="Result"
      onClose={onHide}
      open={show}
      showCloseIcon
    >
      <div className="display-delta">
        <div className="display-delta-table mb-3">
          <DisplayDeltaProposalTable
            displayImpactTableData={displayTableData}
            isEbitEdit={true}
          />
        </div>
        <div className="exceptionCaseDetailTable">
          <DisplayDeltaTableDetails data={vesselPerformanceComparisonsList} />
        </div>
      </div>
    </Modal>
  );
};

export default DisplayDeltaModal;
