import './SkeletonLoader.scss';
interface IPROPS {
  isLoader?: any;
}
export const SkeletonLoader = ({ isLoader }: IPROPS) => {
  const loaderCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <table className={`tg ${!isLoader ? 'hideTable' : ''}`}>
      <thead>
        <tr>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
          <th className='tg-cly1'>
            <div className='line'></div>
          </th>
        </tr>
      </thead>

      {loaderCount.map((index) => (
        <tbody key={loaderCount[index] + 2}>
          <tr key={loaderCount[index] + 3}>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
            <td className='tg-cly1'>
              <div className='line'></div>
            </td>
          </tr>
        </tbody>
      ))}
    </table>
  );
};
