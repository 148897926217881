import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";
import jwt_decode from "jwt-decode";
export const getToken = async (scopes: any) => {
  const account = await msalInstance.getActiveAccount();
  if (account) {
    const response = await msalInstance.acquireTokenSilent({
      account: account,
      scopes: scopes,
    });

    return response.accessToken;
  }
};
export const getUserRole = async () => {
  let accessToken = await getToken(protectedResources.ned.scopes.read);
  let decoded: any = [];
  if(accessToken){
    decoded = jwt_decode(accessToken);
    if (decoded?.roles?.length === 0 || decoded?.roles === undefined) {
      console.log("User => No User Role")
      return "noAccess";
    } else {
      const userRole = decoded?.roles[0];
      console.log("User =>", userRole)
      return userRole;
    }
  }

};
export const getUserMailID = async () => {
  const account = msalInstance.getActiveAccount();
  const user_mailID = account?.username;
  return user_mailID;
};
