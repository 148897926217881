// Anchor Components
import { TableV2 } from "@anchor/react-components/dist/lib/components";

// Models & Styles
import { VesselPerformanceComparisonModel } from "./DisplayDeltaModel";
import "./DisplayDelta.scss";

interface DisplayTableDetailsProps {
  data: VesselPerformanceComparisonModel[];
}

//Function to calculate the total of the baseline and proposal
const calculateTotals = (data, columns) => {
  if (!columns?.length) return {};
  const totals = {};
  columns?.forEach((column) => {
    if (column?.accessorKey) {
      totals[column.accessorKey] = data.reduce(
        (sum, row) => sum + (row[column.accessorKey] || 0),
        0
      );
    }
  });
  return totals;
};

const DisplayDeltaTableDetails = ({ data }: DisplayTableDetailsProps) => {
  const getCellValue = (getValue, row) => {
    const result = Math.round(getValue() * 100) / 100;
    return (
      <div
        className={`cell-value ${
          row.original.vesselName == "Total" && "total-value"
        }`}
        title={result.toLocaleString()}
      >
        {result.toLocaleString()}
      </div>
    );
  };
  const deltaValueDisplay = (deltaValue) => {
    const result = Math.round(deltaValue * 100) / 100;
    return <div className="delta-value">{result.toLocaleString()}</div>;
  };

  const column = [
    {
      id: "vesselName",
      header: "Vessel Name",
      accessorKey: "vesselName",
      enableSorting: false,
      cell: ({ getValue, row }) => (
        <div
          className={`${
            row.original.vesselName == "Total" && "total-value"
          } float-start`}
        >
          <span className="mx-3">{getValue()}</span>
        </div>
      ),
      footer: () => <div className="delta-value float-start">Delta</div>,
    },
    {
      id: "baseline",
      header: () => (
        <div className="baseline-header p-2">
          <span className="mx-3">Base Line</span>
        </div>
      ),
      accessorKey: "baseline",
      size: 150,
      enableSorting: false,
      cell: ({ getValue, row }) => (
        <div className="float-start">{getCellValue(getValue, row)}</div>
      ),
      footer: () => (
        <div className="delta-value float-start">
          {deltaValueDisplay(deltaValue)}
        </div>
      ),
    },
    {
      id: "proposal",
      header: () => (
        <div className="proposal-header p-2">
          <span className="mx-3">Proposal</span>
        </div>
      ),
      accessorKey: "proposal",
      enableSorting: false,
      cell: ({ getValue, row }) => (
        <div className="float-start">{getCellValue(getValue, row)}</div>
      ),
      footer: " ",
    },
  ];

  //Variable to store the total of the baseline and proposal
  const totals = calculateTotals(data, column);
  const totalRow: any = column?.reduce((row, column) => {
    row[column?.accessorKey] =
      column?.accessorKey === "vesselName"
        ? "Total"
        : totals[column?.accessorKey];
    return row;
  }, {});
  const deltaValue = totalRow?.proposal - totalRow?.baseline;
  //Adding the total row to the data
  const tableData = [...data, totalRow];
  return (
    <div className="delta-table">
      <TableV2
        columnData={column}
        defaultData={tableData}
        stickyHeader={true}
        maxHeight={305}
        maxWidth={700}
      />
    </div>
  );
};
export default DisplayDeltaTableDetails;
