// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-device-width: 1023px) and (max-device-width: 1650px) and (-webkit-min-device-pixel-ratio: 1) {
  .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
    min-height: 44px;
  }
  .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding-left: 6px;
    padding-right: 6px;
  }
  .rdt_Table .rdt_TableBody .rdt_TableRow {
    min-height: 40px;
  }
  .rdt_Table .rdt_TableBody .rdt_TableCell {
    padding-left: 6px;
    padding-right: 6px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Styles/mediaQuery.scss"],"names":[],"mappings":"AAAA;EAMY;IACI,gBAAA;EAJd;EAKc;IACI,iBAAA;IACA,kBAAA;EAHlB;EAQU;IACI,gBAAA;EANd;EAQU;IACI,iBAAA;IACA,kBAAA;EANd;AACF","sourcesContent":["@media screen \n  and (min-device-width: 1023px) \n  and (max-device-width: 1650px) \n  and (-webkit-min-device-pixel-ratio: 1) { \n    .rdt_Table{\n        .rdt_TableHead{\n            .rdt_TableHeadRow{\n                min-height: 44px;\n                .rdt_TableCol{\n                    padding-left: 6px;\n                    padding-right: 6px;\n                }\n            }\n        }\n        .rdt_TableBody{\n            .rdt_TableRow{\n                min-height: 40px;\n            }\n            .rdt_TableCell{\n                padding-left: 6px;\n                padding-right: 6px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
