import React, { createContext, useContext, ReactNode } from "react";
import { LocationModel } from "../utils/Constants";

interface ILocationContextProps {
  children: ReactNode;
}

type TLocationContextValue = LocationModel[];

const LocationContext = createContext<TLocationContextValue | undefined>(
  undefined
);

export const LocationProvider: React.FC<ILocationContextProps> = ({
  children,
}) => {
  return (
    <LocationContext.Provider value={[]}>{children}</LocationContext.Provider>
  );
};

export const useLocationData = (): TLocationContextValue => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocationData must be used within a LocationProvider");
  }
  return context;
};
