import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { FbpTradeOwnerResponseModel } from "../model";
interface TableProps {
  tableData: FbpTradeOwnerResponseModel[];
  userRole?: string;
  sectionRole?: string;
}
const TradeOwnerListTable = ({
  tableData,
  userRole,
  sectionRole,
}: TableProps) => {
  const columns: TableColumn<FbpTradeOwnerResponseModel>[] =
      [
        {
          name:sectionRole === 'FBP' ? "Finance Business Partner" : sectionRole === "HNOP" ? "Head Of Ocean Network Product" : "Deployment Head",
          cell: (row) => (row.reviewerName !== null ? <span className="drivers-value" title={row.reviewerName}>{row.reviewerName}</span> : ""),
          sortable: true,
          width: "35%",
        },
        {
          name: "Answer",
          cell: (row) =>
            row.requestStatus !== null ? (
              row?.requestStatus === "Accepted" ? (
                <span className="accepted">{row?.requestStatus}</span>
              ) : row?.requestStatus === "Rejected" ? (
                <span className="rejected">{row?.requestStatus}</span>
              ) : (
                <span className="pending">{row?.requestStatus}</span>
              )
            ) : (
              ""
            ),
          sortable: true,
          width: "12%",
        },
        {
          name: "Reason",
          selector: (row) => (row.rejectionReasonType !== null ? row.rejectionReasonType : ""),
          sortable: true,
          width: "18%",
        },
        {
          name: "Comment",
          cell: (row) => (row.rejectedComment !== null ? <span className="drivers-value" title={row.rejectedComment}>{row.rejectedComment}</span> : ""),
          sortable: true,
          width: "30%",
        },
      ]
  return (
    <div className="tradeOwner-table-list table-section">
      <DataTable columns={columns} data={tableData} />
    </div>
  );
};
export default TradeOwnerListTable;
