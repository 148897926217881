export const InfoText = [
    {
        label: "Back Haul (Captura Intake (TEU))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Partner Allocation (%))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Partner Allocation (TEU))",
        details: "",
        value: "BH Partner Allocation (%) * BH Captura Intake (TEU)"
    },
    {
        label: "Back Haul (MSK Allocation (TEU))",
        details: "",
        value: "BH Captura Intake (TEU) - BH Partner Allocation (TEU)"
    },
    {
        label: "Back Haul (MSK Dry Demand (TEU))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (MSK Reefer Demand (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Dry CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Reefer CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Utilization)",
        details: "",
        value: "((BH MSK Reefer Demand (FFE) * 2) + BH MSK Dry Demand (TEU)) / BH MSK Allocation (TEU)"
    },
    {
        label: "Back Haul (% to Nominal)",
        details: "",
        value: "((BH Captura Intake (TEU) - BH MSK Allocation (TEU)) + BH MSK Dry Demand (TEU) + (BH MSK Reefer Demand (FFE) * 2)) / Nominal Capacity"
    },
    {
        label: "Daily CONS (MT)",
        details: "",
        value: "Round Trip CONS (MT)/ Round Trip (Days)"
    },
    {
        label: "gCo2",
        details: "Retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Head Haul (Captura Intake (TEU))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Partner Allocation (%))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Partner Allocation (TEU))",
        details: "",
        value: "HH Partner Allocation (%) * HH Captura Intake (TEU)"
    },
    {
        label: "Head Haul (MSK Allocation (TEU))",
        details: "",
        value: "HH Captura Intake (TEU) - HH Partner Allocation (TEU)"
    },
    {
        label: "Head Haul (MSK Dry Demand (TEU))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (MSK Reefer Demand (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Dry CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Reefer CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Utilization)",
        details: "",
        value: "((HH MSK Reefer Demand (FFE) * 2) + HH MSK Dry Demand (TEU)) / HH MSK Allocation (TEU)"
    }, {
        label: "Head Haul (% to Nominal)",
        details: "",
        value: "((HH Captura Intake (TEU) - HH MSK Allocation (TEU)) + HH MSK Dry Demand (TEU) + (HH MSK Reefer Demand (FFE) * 2)) / Nominal Capacity"
    },
    {
        label: "MSK Year Allocation (TEU)",
        details: "",
        value: "Round Trip MSK Allocation (TEU) * ( 52 / ( Round Trip (Days) / 7 ))",
    },
    {
        label: "MSK Year demand (TEU)",
        details: "",
        value: "Round Trip Demand (TEU) * ( 52 / ( Round Trip (Days) / 7 ))",
    },
    {
        label: "MtCo2",
        details: "Retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Nominal Capacity",
        details: "Data retrieved from CHAMPS / Manual Input",
        value: ""
    },
    {
        label: "Nopat ($/Yr)",
        details: "",
        value: "((HH MSK Dry Demand (TEU) * (HH Dry CY (FFE) / 2) + HH MSK Reefer Demand (FFE) * HH Reefer CY (FFE) + BH MSK Dry Demand (TEU) * (BH Dry CY (FFE) /2) + BH MSK Reefer Demand (FFE) * BH Reefer CY (FFE)) * 52 / (Round Trip (Days) / 7)) - Yearly Cost ($)"
    },
    {
        label: "One Off ($)",
        details: "Manual input",
        value: ""
    },
    {
        label: "Round Trip (NM)",
        details: "Data retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Round Trip (Days)",
        details: "Data retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Round Trip CONS (MT) ",
        details: "Data retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Round Trip CONS ($) ",
        details: "Fuel consumption * bunker price. Fuel consumption from Star connect and bunker price from BOPS(Bunker Operations).",
        value: ""
    },
    {
        label: "Round Trip TC ($)",
        details: "",
        value: "TCD ($) * Round Trip (Days)"
    },
    {
        label: "Round Trip PEX ($) ",
        details: " Port expense",
        value: ""
    },
    {
        label: "Round Trip COST ($) ",
        details: "Cosumption (USD) + (R/T days * TCD) + PEX",
        value: ""
    },
    {
        label: "Round Trip MSK Allocation (TEU)",
        details: "",
        value: "HH MSK Allocation (TEU) + BH MSK Allocation (TEU)"
    },
    {
        label: "Round Trip Demand (TEU)",
        details: "",
        value: "(BH MSK Dry Demand (TEU) + HH MSK Dry Demand (TEU)) + (HH MSK Reefer Demand (FFE) + BH MSK Reefer Demand (FFE)) * 2"
    },
    {
        label: "Slot Cost",
        details: "",
        value: "Yearly Cost ($) / MSK Year Allocation (TEU)"
    },
    {
        label: "TCD ($)",
        details: "Manual input",
        value: ""
    },
    {
        label: "Unit Cost",
        details: "",
        value: "Yearly Cost ($) / MSK Year demand (TEU)"
    },
    {
        label: "Vessel Name",
        details: "Data retrieved from Schedule Planner",
        value: ""
    },
    {
        label: "Yearly Cost ($)",
        details: "",
        value: "((52 / (Round Trip (Days) / 7 )) * Round Trip COST ($) ) + One Off ($) + (TCD ($) * 365)"
    },
];