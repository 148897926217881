import { useEffect, useState } from "react";

const UtcCurrentDateTime = () => {
    let currDate = new Date(new Date().toISOString().slice(0, -1)).toLocaleString();
    const [currDateTime, setCurrDateTime] = useState<any>(currDate);
    useEffect(() => {
        let secTimer = setInterval(() => {
            let currDate = new Date(new Date().toISOString().slice(0, -1)).toLocaleString();
            setCurrDateTime(currDate);
        }, 1000);
        return () => clearInterval(secTimer);
    }, []);
    return (
        <span> {currDateTime}</span>
    );
};
export default UtcCurrentDateTime;
