//React
import { useState } from 'react';
//Components
import { Input, Modal, TableV2, Tabs } from '@anchor/react-components/dist/lib/components';
import { InfoText } from './DtcoInfoData';
import NoData from '../../../Components/NoData/NoData';
//Styles
import './DtcoInfo.scss';
interface IPROPS {
  showInfoModal: boolean;
  setShowInfoModal: (show: boolean) => void;
}

const DtcoInfoDetails = ({
  showInfoModal,
  setShowInfoModal,
}: IPROPS) => {
  const [searchValue, setSearchValue] = useState("");
  const [tab, setTab] = useState<any>([
    {
      active: true,
      id: 'info',
      title: 'DTCO Information'
    },
    // {
    //   active: false,
    //   id: 'Simulation',
    //   title: 'Simulation Information'
    // },
  ]);
  const [dtcoInfo, setDtcoInfo] = useState(InfoText);
  const [selectedTab, setSelectedTab] = useState('info');
  const rowHeaderName = (row: any) => {
    return row?.original?.label
  }

  const handleInfoSearch = (e: any) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    const data = InfoText?.filter(
      (item) =>
        item.label &&
        item.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setDtcoInfo(data);
  };
  const rowValue = (row: any) => {
    if (row?.original.value) {
      return <div className='formula-value'>
        {row?.original.value}
      </div>
    } else {
      return row?.original.details
    }

  }
  const columns = [
    {
      id: "label",
      accessorKey: "label",
      header: "Name",
      alignData: "center",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="m-2">
          {rowHeaderName(row)}
        </div>
      ),
    },
    {
      id: "value",
      accessorKey: "value",
      header: "Description/formula",
      alignData: "center",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="m-2 row-value">
          {rowValue(row)}
        </div>
      ),
    },
  ];

  return (<Modal
    onClose={() => setShowInfoModal(false)}
    backdropcloseactiondisabled
    heading='Information'
    open={showInfoModal}
    dimension='medium'
    width='50%'
    height='80%'
  >
    <div className='dtco-info-details-main'>
      <div className='dtco-info-label-search pb-2'>
        Here you can search with Info Name for the DTCO Information
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <Tabs
            items={tab}
            onClick={(id) => {
              setSelectedTab(id?.selectedItemId)
              setTab(id?.updatedTabData)
            }}
            fit='small'
            type="default"
          />
        </div>
        <div>
          <div className="search">
            <Input
              clearButton
              fit="small"
              icon="magnifying-glass"
              iconPosition="left"
              id="searchInput"
              onChange={handleInfoSearch}
              placeholder="Search for Info Name"
              value={searchValue}
              onClear={() => {
                setSearchValue("");
                setDtcoInfo(InfoText);
              }}
              variant="default"
              width={100}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
        </div>
      </div>
      <div>
        {
          selectedTab === 'info' ?
            <div className='dtco-info-details'>
              {
                  <TableV2
                    columnData={columns}
                    gridLine="both"
                    fit="small"
                    hidePageSizeOptions={true}
                    defaultData={dtcoInfo}
                  />
              }
            </div>
            :
            <>
            {/* In future will add the simulation tab*/}
              <div>
                <NoData /> 
              </div>
            </>
        }
      </div>
    </div>
  </Modal>);
}

export default DtcoInfoDetails;