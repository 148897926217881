import {
  Button,
  Modal,
} from "@anchor/react-components/dist/lib/components";
import './AcceptProposal.scss';

interface IPROPS {
  isOpen?: boolean;
  onClose?: any;
  modalHeader?: string;
  modalBody?: string;
  modalButton?: any;
  submitFun?: any;
}

const ConfirmationModalPopUp = ({
  isOpen,
  onClose,
  modalHeader,
  modalBody,
  modalButton,
  submitFun
}: IPROPS) => {
  const handleSubmit = () => {
    submitFun();
    onClose();
  }
  return (
      <Modal
        open={isOpen}
        width="auto"
        height="auto"
        showCloseIcon={false}
        actions={{
          primaryAction: (
            <Button
              label={modalButton?.primary}
              onClick={handleSubmit}
              fit="small"
              type="submit"
              data-testid='confirm-submit-button'
            />
          ),
          secondaryAction: (
            <Button
              label={modalButton?.secondary}
              fit="small"
              onClick={onClose}
              variant="outlined"
            />
          ),
        }}
      >
        <div className="accept-reject-proposal">
        <div className="modal-headerSection">
          <h1 className="modal-header">{modalHeader}</h1>
        </div>
        <div>{modalBody}</div>  
        </div>
      </Modal>
    
  );
};
export default ConfirmationModalPopUp;
