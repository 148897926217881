//React
import { useEffect, useState } from "react";

// Anchor Components
import {
  NumberStepper,
  TableV2,
} from "@anchor/react-components/dist/lib/components";

// Model & Styles
import { DtcoModelDataDisplay } from "./DisplayDeltaModel";
import "./DisplayDelta.scss";

interface DisplayTableDetailsProps {
  displayImpactTableData: DtcoModelDataDisplay[];
  isEbitEdit: boolean;
}

const DisplayDeltaProposalTable = ({
  displayImpactTableData,
  isEbitEdit,
}: DisplayTableDetailsProps) => {
  const [data, setData] = useState<DtcoModelDataDisplay[]>(
    displayImpactTableData
  );

  useEffect(() => {
    setData(displayImpactTableData);
  }, [displayImpactTableData]);

  //NopatRT calculation
  const getNopatRT = (nopat, roundTripDays, nopatRT) => {
    if (nopat === 0) {
      return 0;
    }
    const nopatPerday = nopat / 365;
    const roundTrip = roundTripDays * nopatRT;
    const resultNopat = nopatPerday * roundTrip;
    return resultNopat;
  };

  //Function to show the input range
  const showInputRange = (getValue, row) => {
    return (
      <div className="ebit-rt">
        {isEbitEdit ? (
          <NumberStepper
            fit="small"
            id="numberStepper"
            max={18}
            min={1}
            name="numberStepper"
            onBlur={function noRefCheck() {}}
            onChange={(e: any) => {
              row.original.ebitByRtDays = e;
              row.original.totalAmount = getNopatRT(
                row.original.fullYearBenefit,
                row.original.roundTripDays,
                e
              );
              setData([...data]);
            }}
            value={getValue()}
          />
        ) : (
          getValue()
        )}
      </div>
    );
  };

  //Display service code with different color for basline and option
  const handleServiceName = (row: any, column: any) => {
    if (row.original.serviceCode && row.original.isBaseline) {
      return (
        <div
          className="highlight-basline-name p-2"
          title={row.original.serviceCode}
        >
          <span className="mx-2">{row.original.serviceCode}</span>
        </div>
      );
    } else if (row.original.serviceCode && !row.original.isBaseline) {
      return (
        <div className="highlight-option-name p-2" title={row.original.serviceCode}>
          <span className="mx-2">{row.original.serviceCode}</span>
        </div>
      );
    }
  };

  //Display vessel name with tooltip
  const getVesselName = (getValue) => {
    return (
      <div className="vessel-name" title={getValue()}>
        {getValue()}
      </div>
    );
  };

  //Common Function to Display the cell value with tooltip
  const getCellNameValue = (getValue) => {
    const result = Math.round(getValue() * 100) / 100;
    return (
      <div className="float-start" title={result.toLocaleString()}>
        {result.toLocaleString()}
      </div>
    );
  };

  const displayImpactTableColumn = [
    {
      id: "serviceCode",
      accessorKey: "serviceCode",
      header: "Service",
      size: 70,
      enableSorting: false,
      cell: ({ row, column }) => handleServiceName(row, column),
    },
    {
      id: "vesselName",
      accessorKey: "vesselName",
      header: "Vessel",
      enableSorting: false,
      size: 150,
      cell: ({ getValue, row }) => (
        <div className="float-start">{getVesselName(getValue)}</div>
      ),
    },
    {
      id: "roundTripDays",
      accessorKey: "roundTripDays",
      title: "R/T Days",
      header: () => <div title="R/T Days">R/T Days</div>,
      size: 60,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "consupmtionInTons",
      accessorKey: "consupmtionInTons",
      header: () => <div title="R/T Bunker MT">{"R/T Bunker MT"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "consumptionInUSD",
      accessorKey: "consumptionInUSD",
      header: () => <div title="R/T Bunker($)">{"R/T Bunker($)"}</div>,
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "portExpense",
      accessorKey: "portExpense",
      header: () => <div title="R/T PEX">{"R/T PEX"}</div>,
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },

    {
      id: "rtCost",
      accessorKey: "rtCost",
      header: () => <div title="R/T Cost">{"R/T Cost"}</div>,
      size: 70,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "yearlyCost",
      accessorKey: "yearlyCost",
      header: () => <div title="Yearly Cost">{"Yearly Cost"}</div>,
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "totalFFEPerRT",
      header: () => <div title="Total FFE per R/T">{"Total FFE / R/T"}</div>,
      size: 100,
      accessorKey: "totalFFEPerRT",
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "averageCyFFE",
      header: () => <div title="AVG CY FFE">{"AVG CY FFE"}</div>,
      size: 90,
      enableSorting: false,
      accessorKey: "averageCyFFE",
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "rtCy",
      accessorKey: "rtCy",
      header: () => <div title="R/T CY">{"R/T CY"}</div>,
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },

    {
      id: "yearCy",
      accessorKey: "yearCy",
      header: () => <div title="Yearly CY">{"Yearly CY"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValue(getValue),
    },
    {
      id: "fullYearBenefit",
      accessorKey: "fullYearBenefit",
      header: () => <div title="Full Year Benefit">{"Full Year Benefit"}</div>,
      size: 100,
      cell: ({ getValue }) => getCellNameValue(getValue),
      enableSorting: false,
    },
    {
      id: "ebitByRtDays",
      accessorKey: "ebitByRtDays",
      header: () => <div title="EBIT R/T">{"EBIT R/T"}</div>,
      size: 80,
      enableSorting: false,
      cell: ({ getValue, row }) => showInputRange(getValue, row),
    },
    {
      id: "totalAmount",
      header: () => <div title="R/T Result">{"R/T Result"}</div>,
      size: 100,
      accessorKey: "totalAmount",
      cell: ({ getValue }) => getCellNameValue(getValue),
      enableSorting: false,
    },
  ];

  return (
    <div className="delta-table">
      <TableV2
        columnData={displayImpactTableColumn}
        defaultData={data}
        stickyHeader={true}
        maxHeight={260}
      />
    </div>
  );
};
export default DisplayDeltaProposalTable;
