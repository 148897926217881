import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import "./UserLoginDetails.scss";
import { Icon } from "@anchor/react-components/dist/lib/components";
const UserLoginDetails = () => {
  const [activeUserName, setActiveUserName] = useState<any>("");
  const [isSignOutOpen, setIsSignOutOpen] = useState(false);
  const { instance } = useMsal();
  let activeAccount: any;

  useEffect(() => {
    if (instance) {
      activeAccount = instance.getActiveAccount();
      setActiveUserName(activeAccount?.name);
    }
  }, [instance]);

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };
  
  const toggle = () => {
    setIsSignOutOpen(!isSignOutOpen);
  };

  return (
    <div className="user-login-details">
      <div className="d-flex align-items-center">
        <span className="me-1">{activeUserName}</span>
        <Icon name={!isSignOutOpen ? "chevron-down": "chevron-up"} onClick={toggle} data-testid="toggle-signout" />
      </div>
      {isSignOutOpen && (
        <div className="dropdown-content mt-2">
          <span className="d-flex align-items-center px-3 py-1" data-testid="sign-out" role="button" onClick={handleLogoutRedirect}>
            Sign out
          </span>
        </div>
      )}
    </div>
  );
};
export default UserLoginDetails;
