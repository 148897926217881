// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/AccessDeniedBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-denied {
  text-align: center;
}
.access-denied .text-section h1 {
  padding-top: 5rem;
}
.access-denied .btn {
  margin-top: 2rem;
}
.access-denied .Image-section {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: 38rem;
  height: 30rem;
}
.access-denied .loader-width {
  width: 45%;
  position: relative;
}
.access-denied .shipLoader {
  animation: OnLoaderFuc;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: normal;
  position: absolute;
  height: 65px;
  top: 14rem;
}
@keyframes OnLoaderFuc {
  0% {
    left: 0px;
  }
  100% {
    left: calc(100% - 180px);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/AccessDenied/AccessDenied.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACQ;EACI,iBAAA;AACZ;AAGI;EACI,gBAAA;AADR;AAGI;EACI,oEAAA;EACA,sBAAA;EACA,aAAA;AADR;AAGI;EACI,UAAA;EACA,kBAAA;AADR;AAGI;EACI,sBAAA;EACA,sBAAA;EACA,mCAAA;EACA,kCAAA;EACA,2BAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AADR;AAGM;EACE;IAAM,SAAA;EAAZ;EACM;IAAO,wBAAA;EAEb;AACF","sourcesContent":[".access-denied{\n    text-align: center;\n    .text-section{\n        h1{\n            padding-top: 5rem;\n        }\n\n    }\n    .btn{\n        margin-top: 2rem;\n    }\n    .Image-section{\n        background: url('../../Assets/Images/AccessDeniedBg.png') no-repeat center;\n        background-size: 38rem;\n        height: 30rem;\n    }\n    .loader-width{\n        width: 45%;\n        position: relative;\n    }\n    .shipLoader {\n        animation: OnLoaderFuc;\n        animation-duration: 5s;\n        animation-iteration-count: infinite;\n        animation-timing-function: ease-in;\n        animation-direction: normal;\n        position: absolute;\n        height: 65px;\n        top: 14rem;\n      }\n      @keyframes OnLoaderFuc {\n        0%   {left: 0px;}\n        100%  {left: calc(100% - 180px);}\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
