import { MultiSelect, Button, Typography } from "@anchor/react-components";
import React, { useState, useEffect } from "react";
import "./RequestPage.scss";

interface IPROPS {
  applyFilters?: any;
  filterData?: any;
  clearFilters?: any;
  isClearFilter?: boolean;
  isCharter?: boolean;
}
export const Filters = ({
  applyFilters,
  filterData,
  clearFilters,
  isClearFilter,
  isCharter,
}: IPROPS) => {
  const [dtcoTypesData, setDTCOTypesData] = useState([]);
  const [dtcoUserdata, setdtcoUserdata] = useState([]);
  const [statusesData, setStatusesData] = useState([]);
  const [driverCategoriesData, setDriverCategoriesData] = useState([]);
  const executedData = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const [selectedTimePeriodData, setSelectedTimePeriodData] = useState([
    "",
    "",
  ]);

  const [selectedDTCOTypes, setSelectedDTCOTypes] = useState([]);
  const [selectDTCOuser, setselectDTCOuser] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedDriverCategories, setSelectedDriverCategories] = useState([]);
  const [selectedIsExcecuted, setSelectedIsExcecuted] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    setSelectedTimePeriodData([event.target.value, endDate]);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    setSelectedTimePeriodData([startDate, event.target.value]);
  };

  useEffect(() => {
    if (filterData?.dtcoTypes?.length > 0) {
      setDTCOTypesData(filterData?.dtcoTypes);
      setdtcoUserdata(filterData?.requestCreatedUsers);
      setStatusesData(filterData?.statuses);
      setDriverCategoriesData(filterData?.dtcoCategorizations);
      setSelectedTimePeriodData([
        filterData?.requestSubmittedToTradeOwner,
        filterData?.requestSubmittedToTradeOwner,
      ]);
    }
  }, [filterData]);

  useEffect(() => {
    const data = {
      dtcoRequestTypes: selectedDTCOTypes,
      requestCreatedUsers: selectDTCOuser,
      statuses: selectedStatuses,
      driverCategories: selectedDriverCategories,
      executedData: selectedIsExcecuted,
      requestSubmittedToTradeOwner: selectedTimePeriodData,
    };
    applyFilters(data);
  }, [
    selectDTCOuser,
    selectedDTCOTypes,
    selectedStatuses,
    selectedDriverCategories,
    selectedIsExcecuted,
    selectedTimePeriodData,
  ]);

  const handleClearFilters = (triggeredBy: string) => {
    triggeredBy === "button" && clearFilters();
    setSelectedDTCOTypes([]);
    setselectDTCOuser([]);
    setSelectedStatuses([]);
    setSelectedDriverCategories([]);
    setSelectedIsExcecuted([]);
    setStartDate("");
    setEndDate("");
    setSelectedTimePeriodData(["", ""]);
  };
  useEffect(() => {
    if (isClearFilter) {
      handleClearFilters("initial");
    }
  }, [isClearFilter]);
  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <Typography variant="h6">Proposal Filters</Typography>
        <Button
          appearance="error"
          id="btnClearFilter"
          icon="arrow-clockwise"
          data-id="clear-filterBtn"
          iconPosition="right"
          justifyItems="center"
          label=""
          fit="small"
          name="ClearFilters"
          onClick={() => handleClearFilters("button")}
          variant="primary"
        />
      </div>
      <section className="filter-section">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <MultiSelect
                  className="multi-select"
                  closeMenuOnScroll
                  fit="medium"
                  id="DTCOTypes_id"
                  isClearable
                  isSearchable
                  label={isCharter ? "TCO Type" : "DTCO Type"}
                  name="MultipleSelectDefault"
                  onChange={(e) => {
                    setSelectedDTCOTypes(e);
                  }}
                  value={selectedDTCOTypes}
                  options={dtcoTypesData}
                  orientation="vertical"
                  placeholder={`Filter by ${ isCharter ? "TCO" : "DTCO"} Types`}
                  renderValue="label"
                  variant="checkbox"
                />
              </div>
              <div className="col-md-4">
                <MultiSelect
                  className="multi-select"
                  closeMenuOnScroll
                  fit="medium"
                  id="DTCOCategories_id"
                  isClearable
                  isSearchable
                  label="Created By"
                  name="MultipleSelectDefault"
                  onChange={(e) => {
                    setselectDTCOuser(e);
                  }}
                  value={selectDTCOuser}
                  options={dtcoUserdata}
                  orientation="vertical"
                  placeholder="Filter by Created By User"
                  renderValue="label"
                  variant="checkbox"
                />
              </div>
              <div className="col-md-4">
                <MultiSelect
                  className="multi-select"
                  closeMenuOnScroll
                  fit="medium"
                  id="Statuses_id"
                  isClearable
                  isSearchable
                  label="Statuses"
                  name="MultipleSelectDefault"
                  value={selectedStatuses}
                  onChange={(e) => {
                    setSelectedStatuses(e);
                  }}
                  options={statusesData}
                  orientation="vertical"
                  placeholder="Filter by Statuses"
                  renderValue="label"
                  variant="checkbox"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <MultiSelect
                  className="multi-select"
                  closeMenuOnScroll
                  fit="medium"
                  id="Drivers_id"
                  isClearable
                  isSearchable
                  label={isCharter ? "Selection Drivers" : "Drivers category"}
                  name="MultipleSelectDefault"
                  value={selectedDriverCategories}
                  onChange={(e) => {
                    setSelectedDriverCategories(e);
                  }}
                  options={driverCategoriesData}
                  orientation="vertical"
                  placeholder="Filter by Driver Categories"
                  renderValue="label"
                  variant="checkbox"
                />
              </div>
              {!isCharter && (
                <div className="col-md-4">
                  <MultiSelect
                    className="multi-select"
                    closeMenuOnScroll
                    fit="medium"
                    id="Executed_id"
                    isClearable
                    isSearchable
                    label="Executed(Yes/No)"
                    name="MultipleSelectDefault"
                    value={selectedIsExcecuted}
                    onChange={(e) => {
                      setSelectedIsExcecuted(e);
                    }}
                    options={executedData}
                    orientation="vertical"
                    placeholder="Filter by Executed"
                    renderValue="label"
                    variant="checkbox"
                  />
                </div>
              )}

              <div className="align-self-sm-end col-md-4">
                <label>Submitted Time Period</label>
                <div className=" d-flex flex-row h-50 justify-content-between align-self-sm-end">
                  <input
                    type="date"
                    max={endDate}
                    className="form-control"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <span className="align-self-md-center p-1">to</span>
                  <input
                    type="date"
                    className="form-control"
                    min={startDate}
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Filters;
