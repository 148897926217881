// Model
import {
  BaseLineData,
  ImpactResult,
  ImpactResultService,
  ProposalData,
  UniqueService,
} from "../../pages/DtcoOverviewPage/ImpactProposalModal/ImpactProposalModel";
import { DtcoModelData } from "../../pages/DtcoOverviewPage/DtcoModel";

// Utils
import permutations from "../ImpactAnalysis";

function useProcessedData(tableData: DtcoModelData[]): {
  vessels: string[];
  result: ImpactResult[];
  proposedImpactData: ProposalData[];
  baselineData: BaseLineData[];
} {
  let proposedImpactData: ProposalData[] = [];
  let baselineData: BaseLineData[] = [];

  // remove the item from tableData group by serviceId if the group has all entries for isImpactBaseline as false 
  tableData = tableData.filter(
    (item) =>
      !tableData
        .filter((data) => data.serviceId === item.serviceId)
        .every((data) => !data.isImpactBaseline)
  );

  const vessels: string[] = tableData
    .filter((item) => item.isImpactBaseline)
    .map((item) => item.vesselName);

  const uniqueServices: UniqueService[] = Array.from(
    new Set(tableData.map((item) => item.serviceId))
  ).map((serviceId) => ({
    serviceName: tableData.find((item) => item.serviceId === serviceId)!
      .serviceName,
    serviceId,
  }));

  const getServiceName = (
    serviceId: number,
    uniqueServices: UniqueService[]
  ) => {
    // get the serviceName from the uniqueServices array
    const service = uniqueServices.find((item) => item.serviceId === serviceId);
    return service ? service.serviceName : "";
  };

  const getBaselineData = (tableData: DtcoModelData[]): BaseLineData[] => {
    baselineData = tableData
      .filter((item) => item.isImpactBaseline)
      .map((item) => ({
        vesselName: item.vesselName,
        serviceName: item.serviceName,
        serviceId: item.serviceId,
        requestSummaryId: item.requestSummaryId,
        nopat: item.nopat ?? 0,
      }));
    return baselineData;
  };

  const result: ImpactResult[] = permutations(tableData);

  // convert to millions and decimal places to 2
  const convertToMillions = (value: number) =>
    Math.round((value / 1000000) * 100) / 100;

  const calculateBaseLineDiff = (
    result: ImpactResult[],
    tableData: DtcoModelData[],
    baselineData: BaseLineData[],
    optionNumber: string,
    serviceId: number
  ) => {
    // loop through result to get the data associated with the optionNumber and get the services array and filter the serviceId and vesselName and get the nopat value's sum
    const proposalOption = result
      .find((item) => item.option === optionNumber)
      ?.services.filter((service) => service.serviceId === serviceId);
    let finalNopat = 0;
    let baseLineNopat = 0;
    // using serviceId and vesselName from tatalNopat get the nopat value from tableData
    let nopat = tableData.find(
      (item) =>
        proposalOption &&
        item.serviceId === proposalOption[0].serviceId &&
        item.vesselName === proposalOption[0].vesselName
    )?.nopat;
    nopat = nopat && convertToMillions(nopat);
    baseLineNopat =
      baselineData.find(
        (item) =>
          proposalOption && item.serviceId === proposalOption[0].serviceId
      )?.nopat ?? 0;
    baseLineNopat = baseLineNopat && convertToMillions(baseLineNopat);
    finalNopat = (nopat && baseLineNopat && nopat - baseLineNopat) || 0;
    return Math.round(finalNopat * 100) / 100;
  };

  const getOptionName = (index: number) => {
    return result[index].option.replace(/\s/g, "").toLowerCase();
  };

  const getProposedImpactData = (
    result: ImpactResult[],
    tableData: DtcoModelData[],
    baselineData: BaseLineData[],
    uniqueServices: UniqueService[]
  ): ProposalData[] => {
    // from the result array loop through the services array and map the correponding serviceId and vesselName to the tableData and get the nopat value in millions with 2 decimal places
    result.forEach((item: ImpactResult, index: number) => {
      item.services.forEach((service: ImpactResultService, i: number) => {
        const data = tableData.find(
          (tableItem: DtcoModelData) =>
            tableItem.serviceId === service.serviceId &&
            tableItem.vesselName === service.vesselName
        );
        if (data?.vesselName) {
          proposedImpactData[i] = {
            ...proposedImpactData[i],
            service: getServiceName(baselineData[i].serviceId, uniqueServices),
            [getOptionName(index)]: calculateBaseLineDiff(
              result,
              tableData,
              baselineData,
              item.option,
              baselineData[i].serviceId
            ),
          };
        }
      });
    });
    return proposedImpactData;
  };
  proposedImpactData = getProposedImpactData(
    result,
    tableData,
    getBaselineData(tableData),
    uniqueServices
  );

  return { vessels, result, proposedImpactData, baselineData };
}

export default useProcessedData;
