import { Button, Modal } from '@anchor/react-components/dist/lib/components';

interface WarningModalProps {
  showInfoModal: boolean;
  setShowInfoModal: (show: boolean) => void;
  checkBaseline: () => boolean;
  checkEmptyVesselName: () => boolean;
  vesselsLength: number;
  madeChanges: boolean;
  isSpecialCase?: boolean;
}

const DtcoImpactWarningModal = ({
  showInfoModal,
  setShowInfoModal,
  checkBaseline,
  checkEmptyVesselName,
  vesselsLength,
  madeChanges,
  isSpecialCase
}: WarningModalProps) => (
  <Modal
    actions={{
      primaryAction: (
        <Button
          label='Dismiss'
          variant='outlined'
          onClick={() => setShowInfoModal(false)}
        />
      ),
    }}
    backdropcloseactiondisabled
    heading='Warnings'
    open={showInfoModal}
    onClose={() => setShowInfoModal(false)}
  >
    <>
      <p>
        You need to address the following concerns when calculating the {isSpecialCase ? "display delta": "impact analysis"}
        :
      </p>
      {!checkBaseline() && (
        <li>
         Please ensure that at least one vessel is chosen as the baseline{isSpecialCase && " and one vessel is chosen as the option"}.
        </li>
      )}
      {/* {checkEmptyVesselName() && (
        <li>Please enter the vessel name for each vessel.</li>
      )} */}
      {vesselsLength < 2 && !isSpecialCase && (
        <li>Minimum 2 vessels must have to calculate impact.</li>
      )}
      {madeChanges && (
        <li>Changes made on vessel details, please Generate Ranking before proceeding with {isSpecialCase ? 'display delta' : 'calculate impact'}.</li>
      )}
    </>
  </Modal>
);

export default DtcoImpactWarningModal;
