import React from "react";
import "./NoData.scss";
import noData from "../../Assets/Images/noData.png";
const NoData = () => {
  return (
    <div className="no-data">
      <div className="content">
        <div className="center-image">
          <img src={noData} alt="No Data" />
        </div>
        <div className="no-data-text">No Data Available</div>
      </div>
    </div>
  );
};
export default NoData;
