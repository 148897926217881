import React, { useEffect, useState } from "react";
import "./InputRange.scss";
interface InputRangeProps {
  valueHandler: (value: number) => void;
  maxValue: number;
  minValue: number;
}
const InputRange = ({valueHandler, maxValue, minValue}:InputRangeProps) => {
  const [count, setCount] = useState(1);

  const increment = () => {
    if(count < maxValue){
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if(count > minValue){
      setCount(count - 1);
    }
  };
  useEffect(()=>{
    valueHandler(count);
  },[count])
  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setCount(value);
    }
  };
  const handleValidation = (event) => {
    const value = parseInt(event.target.value, 10);
    if( value < minValue || value > maxValue){
      event.preventDefault();
    }else{
      handleChange(event)
    }
  };
  return (
    <div className="input-container">
      <button className="left-btn" onClick={decrement}>-</button>
      <input type="number" value={count}  onChange={handleValidation} />
      <button className="right-btn" onClick={increment}>+</button>
    </div>
  );
};
export default InputRange;
