import logo from "../../Assets/Images/ned-logo.png";
import "./Header.scss";
import UserLoginDetails from "../../Components/UserLogin/UserLoginDetails";
import UtcCurrentDateTime from "../../Components/UTCDateTime/UtcCurrentDateTime";
import { Button } from "@anchor/react-components/dist/lib/components";
import { useState } from "react";
import DtcoInfoDetails from "../../pages/DtcoOverviewPage/DtcoInfo/DtcoInfoDetails";
const Header = () => {
  const [infoDetails, setInfoDetails] = useState(false);
  return (
    <div className="header-section">
      <div className="d-flex justify-content-between">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="timezone mt-1"> UTC Time:  <UtcCurrentDateTime /></div>
          <div className="align-items-center d-flex user-info">   
            <UserLoginDetails />
            <span className="it-help"><a target="_blank" href="https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=f74c98aefb6a4a90c360fe57aeefdc4e">IT help</a></span>
            <div className="px-1">
              <Button label="" icon="info-circle" fit="small" variant="outlined"title={"Guide Information"} onClick={() => setInfoDetails(true)} />
            </div>
          </div>
          {infoDetails && <DtcoInfoDetails showInfoModal={infoDetails} setShowInfoModal={setInfoDetails} />}
      </div>
     </div>
  );
};
export default Header;
