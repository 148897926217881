// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accept-reject-proposal .modal-header {
  font-family: Maersk Headline, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  padding: 0px 0px;
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/FBPValidation/AddOnComponent/AcceptProposal.scss","webpack://./src/Styles/mixins.scss"],"names":[],"mappings":"AAGQ;ECaN,wCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;ADdF","sourcesContent":["\n@use \"../../../Styles/mixins.scss\";\n.accept-reject-proposal {\n        .modal-header {\n            @include mixins.modal-header-font;\n          }\n}\n","@mixin header-font {\n  font-family: Maersk Headline, sans-serif;\n  font-size: 36px;\n  font-weight: 300;\n  line-height: 40px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n@mixin sub-header-font {\n  font-family: Maersk Headline, sans-serif;\n  font-size: var(--font-size-lg);\n  font-weight: 700;\n  line-height: 24px;\n  text-align: left;\n}\n@mixin modal-header-font {\n  font-family: Maersk Headline, sans-serif;\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin: 0px;\n  padding: 0px 0px;\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
