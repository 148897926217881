// AnchorUI
import Tooltip from '@anchor/react-components/dist/lib/components/Tooltip';

interface EllipsisTextProps {
  text: string;
  maxLength: number;
}

const EllipsisText = ({ text, maxLength }: EllipsisTextProps) => {
  const displayText =
    text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
      <Tooltip appearance='default' content={text} position='top' width='auto'>
        <div title={text} data-testid='ellipsis-text'>{displayText}</div>
      </Tooltip>
  );
};

export default EllipsisText;
