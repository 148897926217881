import React from "react";
import { Tag } from "@anchor/react-components";

interface IPROPS {
  status?: string;
  diploymentUse?: boolean;
}

const StatusBadge = ({ status, diploymentUse }: IPROPS) => {
  const getStatusVariant = () => {
    if(diploymentUse){
      return "default"
    } else{
      switch (status) {
        case "DPL Assessment":
          return "info";
        case "Completed":
          return "success";
        case "Proposal Sent":
          return "info";
        case "DeploymentHead Rejected":
          return "error";
        case "FBP Rejected":
          return "error";
        case "Escalate To HONP":
          return "info";
        case "HONP Rejected":
          return "error";
        case "DPL to communicate plan":
          return "info";
       case "DPL action pending":
          return "warning";
        default:
          return undefined;
      }
    }

  };

  return <Tag fit="small" label={diploymentUse ? "For DDL use" : status} onClose={() => {}} variant={getStatusVariant()} />;
};

export default StatusBadge;
