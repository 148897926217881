// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-login-details .dropdown-content {
  margin-left: 2.5rem;
  position: absolute;
  background-color: var(--color-white);
  border: 1px solid var(--border-haeder-color);
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserLogin/UserLoginDetails.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,kBAAA;EACA,oCAAA;EACA,4CAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;AAAJ","sourcesContent":[".user-login-details {\n  .dropdown-content {\n    margin-left: 2.5rem;\n    position: absolute;\n    background-color: var(--color-white);\n    border: 1px solid var(--border-haeder-color);\n    border-radius: 4px;\n    z-index: 1;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
