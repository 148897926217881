// React
import { useEffect, useState } from "react";

// Anchor UI
import {
  Icon,
  Menu,
  toastEmitter,
  Notification,
  LoadingIndicator,
} from "@anchor/react-components/dist/lib/components";

// styles
import "./SimulationDataFeedback.scss";

// Services
import { deleteAPI, getAPI, postAPI } from "../../../../Services/APIservices";
import { AppString } from "../../../../utils/AppString";
import { Feedback } from "../../Model";

// Components
import CommentEditor from "../../../../Components/CommentEditor/CommentEditor";

interface IProps {
  isDrawerOpen: boolean;
  requestId: string | undefined;
}

const SimulationDataFeedback = ({ isDrawerOpen, requestId }: IProps) => {
  const [editIndex, setEditIndex] = useState(0);
  const [editingNote, setEditingNote] = useState<any | null>();


  const [editMode, setEditMode] = useState(false);

  let data: Feedback[] = [];
  const [resultFeedback, setResultFeedback] = useState<Feedback[]>(
    localStorage.getItem(`simulationCommentsHistory`)
      ? JSON.parse(localStorage.getItem(`simulationCommentsHistory`) as string)
      : []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [isDrawerOpen]);

  const getSimulationResultFeedback = async () => {
    const url = `simulation-api/Simulation/GetSimulationRecomendationCommentsAsync/${requestId}`;
    setIsLoading(true);
    await getAPI(url)
      .then((response) => {
        data = response;
        setIsLoading(false);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while fetching simulation result feedback.",
          },
          { type: "error" }
        );
        setIsLoading(false);
        console.error("An error occurred:", error);
      });
  };


  const fetchData = async () => {
    await getSimulationResultFeedback();
    if (JSON.stringify(data) !== JSON.stringify(resultFeedback)) {
      setIsLoading(true);
      localStorage.setItem(`simulationCommentsHistory`, JSON.stringify(data));
      setResultFeedback(data);
    }
    setIsLoading(false);
  };

  const onSubmit = async (id, comment) => {
    const url = `simulation-api/Simulation/SaveRecomendationCommentsAsync/${requestId}`;
    const payload = {
      id: id,
      comment: comment,
    };

    await postAPI(url, payload)
      .then((response) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Feedback saved successfully",
          },
          { type: "success" }
        );
        fetchData();
        setEditMode(false);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while saving feedback.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  const deleteSelectedFeedback = async (id) => {
    await deleteAPI(
      `simulation-api/Simulation/DeleteRecomendationComment/${id}`
    ).then((response) => {
      if (response === true) {
        toastEmitter(
          { className: "customClassName", title: "Note deleted successfully" },
          { type: "success" }
        );
        setResultFeedback(
          resultFeedback.filter((comment) => comment.id !== id)
        );
        fetchData();
      } else {
        toastEmitter(
          { className: "customClassName", title: "Something went wrong" },
          { type: "error" }
        );
      }
    });
  };

  const checkForSelectedAction = async (e: any, index, id) => {
    if (e?.items[0]?.value === "Edit") {
      setEditMode(true);
      setEditIndex(index);
    } else if (e?.items[0]?.value === "Delete") {
      deleteSelectedFeedback(id);
    }
    setMenuOpen(false);
  };

  const handleCancelMessage = () => {
    setEditMode(false);
  };

  return (
    <div className="simulation-result-feedback">
      <div className="align-items-center d-flex justify-content-between px-3 mt-3">
        <h1 className="fs-5 fw-normal">{AppString.simulationResultLogTitle}</h1>
        <span>{isLoading && <LoadingIndicator />}</span>
      </div>

      {resultFeedback?.length > 0 ? (
        <>
          {resultFeedback.map((comment, index: number) => (
            <div
              key={comment.id}
              className="my-4 px-3 simulation-result-feedback-card"
            >
              <div className="mb-1">{comment.createdBy}</div>

              <div className="p-2 simulation-result-feedback-comment">
                <div key={index}>
                  <div>
                    <div className="row">
                      <div
                        className={
                          !(editMode && index === editIndex)
                            ? "col-11"
                            : "col-12"
                        }
                      >
                        {!(editMode && index === editIndex) && (
                          <div className="overflow-auto simulation-result-feedback-comment-edit">
                            {comment.comment}
                          </div>
                        )}
                        {editMode && index === editIndex && (
                          <CommentEditor
                            message={comment?.comment}
                            id={comment?.id}
                            onSave={onSubmit}
                            onCancel={handleCancelMessage}
                          />
                        )}
                      </div>
                      {!(editMode && index === editIndex) && (
                        <div className="col-1 p-0">
                          <span id={`${comment.id}`}>
                            <Menu
                              fit="medium"
                              hasArrow
                              listData={[
                                {
                                  items: [
                                    {
                                      value: "Edit",
                                    },
                                    {
                                      value: "Delete",
                                    },
                                  ],
                                },
                              ]}
                              maxheight="200px"
                              position="top-center"
                              trigger="click"
                              width="130px"
                              open={editingNote?.id === comment.id && menuOpen}
                              listChange={(e) =>
                                checkForSelectedAction(
                                  e,
                                  index,
                                  comment.id,
                                )
                              }
                            >
                              <div id={`${comment.id}`}>
                                <Icon
                                  name="ellipsis-vertical"
                                  onClick={() => {
                                    setEditingNote(comment);
                                    setMenuOpen(!menuOpen);
                                  }}
                                />
                              </div>
                            </Menu>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="float-end mt-1">
                {new Date(comment.createdOn).toDateString()}
              </div>
            </div>
          ))}
        </>
      ) : (
        <Notification
          variant="info"
          body={AppString.simulationNoFeedback}
          icon="file"
          className="m-3"
        />
      )}
    </div>
  );
};

export default SimulationDataFeedback;
