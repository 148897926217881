import React from "react";
import "./PageLoader.scss";
interface IPROPS{
    isLoader?:boolean
}
const PageLoader = ({isLoader}:IPROPS) => {
  return (
    <div className={`${isLoader ? 'showLoader' : 'hideLoader'} page-loader`}>
      <section>
        <div className="content">
          <h2>NED</h2>
          <h2>NED</h2>
          <div className="loader-line"></div>
        </div>
       
      </section>
      
    </div>
  );
};
export default PageLoader;
