// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-delta .display-delta-table .ebit-rt .numberStepper {
  position: relative;
  top: 4px;
  margin: -4px;
}
.display-delta .display-delta-table .ebit-rt .numberStepper button {
  padding: 2px;
  min-height: 18px;
  min-width: 24px;
}
.display-delta .display-delta-table .ebit-rt .numberStepper input {
  padding: 2px;
  min-height: 1rem;
  min-width: 1rem;
  max-height: 1.45rem;
  font-size: var(--font-size-sm);
}
.display-delta .delta-table table th,
.display-delta .delta-table table td {
  font-size: var(--font-size-sm);
}
.display-delta .delta-table table tbody td:first-child {
  padding: 0px;
}
.display-delta .delta-table .total-value {
  color: var(--color-maersk-blue);
  font-weight: bold;
}
.display-delta .delta-table .baseline-header,
.display-delta .delta-table .highlight-basline-name {
  background-color: var(--table-select-bg-color);
}
.display-delta .delta-table .proposal-header,
.display-delta .delta-table .highlight-option-name {
  background-color: var(--table-select-option-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/DtcoOverviewPage/DisplayDelta/DisplayDelta.scss"],"names":[],"mappings":"AAGY;EACI,kBAAA;EACA,QAAA;EACA,YAAA;AAFhB;AAIgB;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AAFpB;AAKgB;EACI,YAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;AAHpB;AAcY;;EAEI,8BAAA;AAZhB;AAgBgB;EACI,YAAA;AAdpB;AAmBQ;EACI,+BAAA;EACA,iBAAA;AAjBZ;AAoBQ;;EAEI,8CAAA;AAlBZ;AAqBQ;;EAEI,qDAAA;AAnBZ","sourcesContent":[".display-delta {\n    .display-delta-table {\n        .ebit-rt {\n            .numberStepper {\n                position: relative;\n                top: 4px;\n                margin: -4px;\n\n                button {\n                    padding: 2px;\n                    min-height: 18px;\n                    min-width: 24px;\n                }\n\n                input {\n                    padding: 2px;\n                    min-height: 1rem;\n                    min-width: 1rem;\n                    max-height: 1.45rem;\n                    font-size: var(--font-size-sm);\n                }\n            }\n        }\n\n\n    }\n\n    .delta-table {\n        table {\n\n            th,\n            td {\n                font-size: var(--font-size-sm);\n            }\n\n            tbody {\n                td:first-child {\n                    padding: 0px;\n                }\n            }\n        }\n\n        .total-value {\n            color: var(--color-maersk-blue);\n            font-weight: bold;\n        }\n\n        .baseline-header,\n        .highlight-basline-name {\n            background-color: var(--table-select-bg-color);\n        }\n\n        .proposal-header,\n        .highlight-option-name {\n            background-color: var(--table-select-option-bg-color);\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
