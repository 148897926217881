import React, { ReactNode } from 'react';
import { LocationProvider } from './LocationContext'; 

interface IAppProviderProps {
  children: ReactNode;
}

const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  return (
    // Wrap to add others providers here
    <LocationProvider>
        {children}
    </LocationProvider>
  );
};

export default AppProvider;
