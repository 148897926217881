import { appRoles } from "../../../utils/Constants";
export const RedirectionCase = (
  id?: number,
  status?: string,
  loggedInUserRole?: string | null,
  isDraftRequest?:boolean
) => {
  if (loggedInUserRole === appRoles.DeploymentUser) {
    switch (status) {
      case "DPL Assessment":
        return isDraftRequest ? `/RequestPage/FBPvalidation?requestId=${id}` : `/RequestPage/DtcoOverviewPage/${id}`;
      default:
        return `/RequestPage/DeploymentValidation?requestId=${id}`;
    }
  }
  if (
    loggedInUserRole === appRoles.DeploymentHead ||
    loggedInUserRole === appRoles.FBP ||
    loggedInUserRole === appRoles.HONP
  ) {
    switch (status) {
      case "DPL Assessment":
        return `/RequestPage/DtcoOverviewPage/${id}`;
      default:
        return `/RequestPage/ProposalValidationScreen/${id}`;
    }
  }
};
