// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-modal .feedback-modal-details .main-div {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  margin-top: 15px;
  height: 11.25rem;
}
.feedback-modal .feedback-modal-details .main-div .top-div {
  display: flex;
}
.feedback-modal .feedback-modal-details .main-div .icon {
  height: 3.5rem;
  width: 4rem;
  background: #99c2e6;
  border-radius: 50%;
  justify-content: center;
  display: inline-flex;
  vertical-align: center;
  align-items: center;
}
.feedback-modal .feedback-modal-details .main-div h5 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.feedback-modal .feedback-modal-details .main-div p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}
.feedback-modal .feedback-modal-details .main-div .text {
  margin-left: 15px;
}
.feedback-modal .feedback-modal-details .main-div .btn-div {
  border: none;
  background: none;
}
.feedback-modal .feedback-modal-details .main-div .btn-div .btn {
  width: 100%;
  background: var(--btn-color);
  border-radius: 0;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Feedback/Feedback.scss"],"names":[],"mappings":"AAGI;EACE,+CAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAFN;AAIM;EACE,aAAA;AAFR;AAKM;EACE,cAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;AAHR;AAMM;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJR;AAOM;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AALR;AAQM;EACE,iBAAA;AANR;AASM;EACE,YAAA;EACA,gBAAA;AAPR;AASQ;EACE,WAAA;EACA,4BAAA;EACA,gBAAA;EACA,SAAA;AAPV","sourcesContent":[".feedback-modal {\n  .feedback-modal-details {\n\n    .main-div {\n      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);\n      padding: 15px;\n      margin-top: 15px;\n      height: 11.25rem;\n\n      .top-div {\n        display: flex;\n      }\n\n      .icon {\n        height: 3.5rem;\n        width: 4rem;\n        background: #99c2e6;\n        border-radius: 50%;\n        justify-content: center;\n        display: inline-flex;\n        vertical-align: center;\n        align-items: center;\n      }\n\n      h5 {\n        color: #000;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 24px;\n      }\n\n      p {\n        color: #000;\n        font-size: 14px;\n        font-style: normal;\n        line-height: 24px;\n      }\n\n      .text {\n        margin-left: 15px;\n      }\n\n      .btn-div {\n        border: none;\n        background: none;\n\n        .btn {\n          width: 100%;\n          background:var(--btn-color);\n          border-radius: 0;\n          border: 0;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
